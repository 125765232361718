<template>
  <div>
    <main class="card-machine-table">
      <div class="header">
        <div class="subtitle">
          <p>Máquinas</p>
          <v-help-circle class="icon" id="subtitle-tooltip-card-machine" />
        </div>
        <Loading
          :class="{ reload: true }"
          @click="() => !loading && getCardMachines()"
        />
      </div>

      <b-tooltip
        target="subtitle-tooltip-card-machine"
        triggers="hover"
        placement="bottom"
      >
        <p class="order-body">
          Gerencie quais máquinas são utilizadas em sua clínica.
        </p>
      </b-tooltip>

      <div class="outages">
        <table>
          <thead class="data-head">
            <tr>
              <th class="head-cell">
                <p>Fornecedor da máquina</p>
              </th>
              <th class="head-cell"></th>
            </tr>
          </thead>

          <tbody class="data-body">
            <tr class="items">
              <div
                class="item"
                v-for="cardMachine in cardMachines"
                :key="cardMachine.id"
              >
                <td class="cell">
                  <p>{{ cardMachine.name }}</p>
                </td>

                <td class="cell margin-left-auto">
                  <div class="receive d-flex">
                    <div class="optionsDiv">
                      <p class="status" v-if="cardMachine.active">Ativo</p>
                      <p class="status" v-else>Inativo</p>
                    </div>

                    <div class="optionsDiv">
                      <toggle-button
                        :sync="true"
                        :height="32"
                        :color="{ checked: '#00C773', unchecked: '#E1E5F4' }"
                        v-model="cardMachine.active"
                        @input="
                          updateCardMachineStatus(
                            cardMachine.id,
                            cardMachine.active
                          )
                        "
                        :disabled="!userHasPermission('FiConMaq3')"
                      />
                    </div>
                    <div
                      v-if="
                        userHasPermission('FiConMaq4') ||
                        userHasPermission('FiConMaq5')
                      "
                      class="actions"
                    >
                      <Icon tooltip="Editar máquina">
                        <Edit
                          class="icon"
                          @click="updateCardMachine(cardMachine)"
                        />
                      </Icon>
                      <Icon tooltip="Remover máquina">
                        <Delete
                          class="icon"
                          @click="openDeleteModal(cardMachine)"
                        />
                      </Icon>
                    </div>
                  </div>
                </td>
              </div>
            </tr>
          </tbody>
        </table>

        <div class="add-btn">
          <button
            v-can="'FiConCat2'"
            @click="$bvModal.show('card-machine-modal')"
          >
            Adicionar
          </button>
        </div>
      </div>
    </main>

    <CardMachineModal :cardMachine="cardMachine" @hidden="hiddenModal" />
  </div>
</template>

<script>
import moment from 'moment'
import api from '../api'
import {
  getCurrentClinic,
  userHasPermission
} from '@/utils/localStorageManager'

export default {
  name: 'cost-center-table',
  components: {
    Icon: () => import('@/components/General/Icon'),
    Delete: () => import('@/assets/icons/delete.svg'),
    Edit: () => import('@/assets/icons/edit.svg'),
    Loading: () => import('@/assets/icons/loading.svg'),
    'v-help-circle': () => import('@/assets/icons/help-circle.svg'),
    CardMachineModal: () =>
      import('@/modules/financial/modals/CardMachineModal.vue')
  },
  data() {
    return {
      clinic: getCurrentClinic(),
      validated: false,
      cardMachines: [],
      cardMachine: null,
      canEdit: userHasPermission('FiConMaq4')
    }
  },
  mounted() {
    this.getCardMachines()
  },
  methods: {
    moment,
    userHasPermission,
    async getCardMachines() {
      const isLoading = this.$loading.show()
      api
        .getCardMachines(this.clinic.id)
        .then(({ data }) => (this.cardMachines = data))
        .catch(err => this.$toast.error(err.message))
        .finally(() => (isLoading.hide()))
    },
    updateCardMachine(cardMachine) {
      this.cardMachine = cardMachine
      this.$bvModal.show('card-machine-modal')
    },
    openDeleteModal(cardMachine) {
      this.$swal({
        icon: 'question',
        html: `<div class="body-alert">Deseja realmente remover <span>${cardMachine?.name}</span>?</div>`,
        showCancelButton: true,
        confirmButtonColor: '#F63220',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true
      }).then(res => {
        if (res.isConfirmed) {
          this.deleteCardMachine(cardMachine.id)
        }
      })
    },
    deleteCardMachine(id) {
      const isLoading = this.$loading.show()
      api
        .deleteCardMachine(id)
        .then(() => {
          this.$toast.success('Máquina removida com sucesso!')
          this.cardMachines = this.cardMachines.filter(el => el.id !== id)
        })
        .catch(err => this.$toast.error(err.message))
        .finally(() => (isLoading.hide()))
    },
    async updateCardMachineStatus(id, active) {
      const isLoading = this.$loading.show()
      try {
        await api.updateCardMachine(id, { active })
        this.$toast.success('Máquina atualizada com sucesso!')
      } catch (err) {
        this.$toast.error(err.message)
      } finally {
        isLoading.hide()
      }
    },
    hiddenModal() {
      this.cardMachine = null
      this.getCardMachines()
    }
  }
}
</script>

<style lang="scss" scoped>
.card-machine-table {
  padding: 40px 10px 0px 10px;
  width: 100%;
  height: 100%;
  .title {
    font-family: 'Red Hat Display';
    font-weight: 700;
    font-size: 24px;
    color: var(--dark-blue);
    margin-bottom: 27px;
  }

  .header {
    width: 100%;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }

  .reload {
    cursor: pointer;
    &.loading {
      animation: load 1.4s infinite linear;
      @keyframes load {
        0% {
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
        }
        100% {
          -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
        }
      }
    }
  }

  .subtitle {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    display: flex;
    align-items: center;
    color: var(--type-active);
    padding-bottom: 24px;
    text-align: left;
    flex-direction: row;
    gap: 8px;

    .icon {
      height: 24px;
      width: 24px;
      stroke: var(--neutral-500);
    }
  }

  .outages {
    box-sizing: border-box;
    border-radius: 8px;

    .order-body {
      border-radius: 8px;

      p {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        color: var(--dark-blue);
        text-align: left;
      }
    }

    .add-btn {
      text-align: center;
      padding: 24px 0;
      border-left: 1px solid var(--neutral-200);
      border-right: 1px solid var(--neutral-200);
      border-bottom: 1px solid var(--neutral-200);
      border-radius: 0 0 7px 7px;

      button {
        transition: 0.3s;
        border: 2px solid var(--blue-500);
        padding: 8px 68px;
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        color: var(--blue-500);
        border-radius: 8px;

        &.saveBtn {
          color: var(--blue-500);
          border: 2px solid var(--blue-500);
        }

        &.cancel {
          color: var(--light-orange-200);
          border: 2px solid #ffdccc;
        }
      }

      button:hover {
        transition: 0.4s;
        color: white;
        background-color: var(--blue-500);
      }
    }

    table {
      table-layout: fixed !important;
      width: 100% !important;
      border-collapse: collapse !important;
    }

    .head-cell {
      text-align: left !important;

      p {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        align-items: center;
        color: var(--type-active);
        text-align: left;
      }
    }

    .cell {
      white-space: normal;
      display: flex;
      align-items: left;
      flex-direction: column;

      p {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        align-items: center;
        color: var(--type-active);
        text-align: left;
      }
    }

    .flexComponent {
      display: flex;
    }

    .flexRow {
      flex-direction: row;
      justify-content: space-around;
    }

    .status-toggle-create {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .center-options .optionsDiv {
      p {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        text-align: right;
        color: var(--type-active);
      }

      svg {
        fill: var(--neutral-500);
      }
    }

    .value-tag {
      max-width: 70%;
      padding: 8px 16px;
      border-radius: 50px;
      background-color: var(--neutral-200);
      color: #000;
      text-align: center !important;
      overflow: hidden;

      &.active {
        background-color: var(--light-orange-200);
      }

      &.negative {
        background-color: #fedad7;
      }
    }

    .data-head {
      tr {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        padding: 12px 24px;
        background: var(--neutral-100);
        color: #525c7a;
        flex-direction: row;
        border-radius: 7px 7px 0px 0px !important;
        border: 1px solid var(--neutral-200);
      }
    }

    .data-body {
      .items {
        display: flex;
        flex-direction: column;
        position: relative;

        .item {
          list-style: none;
          padding: 24px;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          border-bottom: 1px solid var(--neutral-200);
          border-left: 1px solid var(--neutral-200);
          border-right: 1px solid var(--neutral-200);

          .times-tag {
            margin: 0 auto;
            border-radius: 50px;
            background-color: var(--neutral-200);
            color: #000;
            text-align: center;

            &.active {
              background: var(--light-orange-200);
            }

            &.negative {
              background: #fedad7;
            }
          }

          .receive {
            display: flex;
            flex-direction: row;
            align-items: center;
            border: none !important;
            text-align: left;
            align-items: center;
            gap: 8px;

            .options {
              cursor: pointer;
              width: 24px;
              height: 28px;
              position: relative;
              display: inline-block;

              .more-icon {
                width: 24px;
                height: 24px;
                stroke: var(--neutral-500);
                transition: all 0.5s;
                cursor: pointer;
              }

              .menu {
                width: 160px;
                position: absolute;
                top: 28px;
                right: 0;
                background: var(--neutral-000);
                border: 1px solid var(--neutral-200);
                box-sizing: border-box;
                box-shadow: 0px 4px 4px rgba(12, 29, 89, 0.1),
                  0px 4px 10px -8px rgba(12, 29, 89, 0.2);
                border-radius: 8px;
                padding: 24px;
                opacity: 0;
                transition: all 0.5s;
                flex-direction: column;
                align-items: start;
                display: none;

                .mtb-12 {
                  margin: 12px 0;
                }

                .btn {
                  font-weight: 600;
                  margin-bottom: 16px;

                  &.remove {
                    color: var(--states-error);
                  }
                }
              }

              &:hover {
                .more-icon {
                  transform: rotate(90deg);
                }

                .menu {
                  opacity: 1;
                  display: flex;
                  z-index: 10;
                }
              }
            }
          }
        }
      }
    }
  }
}

.margin-left-auto {
  margin-left: auto;
}

.delete-btn {
  height: 37px !important;
  font-weight: 800 !important;
  margin-left: 20px;
  border: none !important;
  border-radius: 8px !important;
}

.actions {
  justify-content: right;
  display: flex;
  .icon {
    width: 24px;
    height: 24px;
    margin-right: 6px;
    cursor: pointer;
  }
}
</style>
