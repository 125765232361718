var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('main',{staticClass:"card-machine-table"},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"subtitle"},[_c('p',[_vm._v("Máquinas")]),_c('v-help-circle',{staticClass:"icon",attrs:{"id":"subtitle-tooltip-card-machine"}})],1),_c('Loading',{class:{ reload: true },on:{"click":() => !_vm.loading && _vm.getCardMachines()}})],1),_c('b-tooltip',{attrs:{"target":"subtitle-tooltip-card-machine","triggers":"hover","placement":"bottom"}},[_c('p',{staticClass:"order-body"},[_vm._v(" Gerencie quais máquinas são utilizadas em sua clínica. ")])]),_c('div',{staticClass:"outages"},[_c('table',[_vm._m(0),_c('tbody',{staticClass:"data-body"},[_c('tr',{staticClass:"items"},_vm._l((_vm.cardMachines),function(cardMachine){return _c('div',{key:cardMachine.id,staticClass:"item"},[_c('td',{staticClass:"cell"},[_c('p',[_vm._v(_vm._s(cardMachine.name))])]),_c('td',{staticClass:"cell margin-left-auto"},[_c('div',{staticClass:"receive d-flex"},[_c('div',{staticClass:"optionsDiv"},[(cardMachine.active)?_c('p',{staticClass:"status"},[_vm._v("Ativo")]):_c('p',{staticClass:"status"},[_vm._v("Inativo")])]),_c('div',{staticClass:"optionsDiv"},[_c('toggle-button',{attrs:{"sync":true,"height":32,"color":{ checked: '#00C773', unchecked: '#E1E5F4' },"disabled":!_vm.userHasPermission('FiConMaq3')},on:{"input":function($event){return _vm.updateCardMachineStatus(
                          cardMachine.id,
                          cardMachine.active
                        )}},model:{value:(cardMachine.active),callback:function ($$v) {_vm.$set(cardMachine, "active", $$v)},expression:"cardMachine.active"}})],1),(
                      _vm.userHasPermission('FiConMaq4') ||
                      _vm.userHasPermission('FiConMaq5')
                    )?_c('div',{staticClass:"actions"},[_c('Icon',{attrs:{"tooltip":"Editar máquina"}},[_c('Edit',{staticClass:"icon",on:{"click":function($event){return _vm.updateCardMachine(cardMachine)}}})],1),_c('Icon',{attrs:{"tooltip":"Remover máquina"}},[_c('Delete',{staticClass:"icon",on:{"click":function($event){return _vm.openDeleteModal(cardMachine)}}})],1)],1):_vm._e()])])])}),0)])]),_c('div',{staticClass:"add-btn"},[_c('button',{directives:[{name:"can",rawName:"v-can",value:('FiConCat2'),expression:"'FiConCat2'"}],on:{"click":function($event){return _vm.$bvModal.show('card-machine-modal')}}},[_vm._v(" Adicionar ")])])])],1),_c('CardMachineModal',{attrs:{"cardMachine":_vm.cardMachine},on:{"hidden":_vm.hiddenModal}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',{staticClass:"data-head"},[_c('tr',[_c('th',{staticClass:"head-cell"},[_c('p',[_vm._v("Fornecedor da máquina")])]),_c('th',{staticClass:"head-cell"})])])
}]

export { render, staticRenderFns }